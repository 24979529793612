@media (max-width: 979px) {
  * {
    &::-webkit-scrollbar {
      width: 3px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: none !important;
    }

    &::-webkit-scrollbar-thumb {
      background: none !important;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background: none !important;
    }
  }

  button {
    width: 100%;
  }

  .container {
    width: 100%;
  }

  .col {
    width: 100% !important;
    padding: 2px 0 !important;
  }

  .time-line {
    width: 100%;

    .group-step {
      flex-direction: column;
      width: 100%;
      gap: 5px;

      .step {
        width: 100%;
      }

      .step-actived {
        width: 100%;
      }
    }
  }

  .group-buttons {
    gap: 5px;
    margin-top: 20px;
    flex-direction: column !important;

    button {
      display: block !important;
    }
  }

  .value-safe {
    word-break: break-all;
    text-align: end;
  }

  .header-token-banner .img-banner img {
    height: auto;
  }

  .group-tag-social {
    flex-direction: column;
    gap: 10px;
  }

  .group-filters {
    flex-direction: column;

    .filter-tab {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .container-launch {
    padding: 20px;
  }

  .home {
    .group-logo-home {
      flex-direction: column-reverse;
      gap: 20px;

      .content-values {
        flex-direction: column;
        gap: 20px;

        img {
          width: 50%;
        }

        .group-info-solpad {
          margin-left: 0px;
          margin-top: 0px;
          width: 100%;

          &__values {
            width: 100%;
            justify-content: space-between;
            border-radius: 16px;
            padding: 10px 30px;

            &--title {
              color: #fbfbfb;
              font-size: 1rem;
            }

            &--values {
              color: #fbfbfb;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }

  .group-links,
  .group-links-footer {
    justify-content: flex-start;
    flex-direction: row;
    overflow: auto;
    gap: 20px;
    width: 100%;
    margin: 20px 0;
  }

  .group-tabs .tabs .tab {
    font-size: 0.8rem !important;
  }

  .group-wallet {
    &__wallet {
      &--items {
        &.center {
          border: none !important;
        }
      }
    }
  }

  .p-x-5 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .stake-earn {
    &__group-input {
      flex-direction: column !important;
      gap: 10px;

      &--input {
        flex-direction: column !important;
        gap: 10px;

        &--item {
          margin: 0 !important;
        }
      }
    }
  }

  .trending {
    height: auto !important;

    &__container {
      width: 100% !important;

      .g-trending {
        display: flex;
        padding-bottom: 5px;
        width: 100% !important;
      }
    }
  }

  .value-sol {
    padding-top: 10px;
    margin-right: 0 !important;
  }

  .group-itens-trending {
    width: 80% !important;
  }

  .card-hover {
    transition: none !important;

    &:hover {
      scale: initial !important;
      transition: none !important;
    }
  }

  .latest-pools-container {
    margin: 10px 0;
  }

  .latest-pools-group-items {
    margin-top: 0 !important;
  }

  .content-latest .latest-pools-title {
    justify-content: center;
  }

  .flex-detail {
    flex-direction: row-reverse;
  }
}
