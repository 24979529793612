@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

* {
  &::-webkit-scrollbar {
    width: 1px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #fbfbfb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-sec);
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: var(--color-sec);
  }
  color: #8b8b8b;
}

.row {
  display: flex;
  flex-wrap: wrap;

  small {
    font-size: 0.7rem;
    color: #49aec0;
    margin-top: -10px;
  }

  .col {
    padding: 10px;

    &.col-1 {
      width: 8.33%;
    }

    &.col-2 {
      width: 16.66%;
    }

    &.col-3 {
      width: 25%;
    }

    &.col-4 {
      width: 33.33%;
    }

    &.col-5 {
      width: 41.66%;
    }

    &.col-6 {
      width: 50%;
    }

    &.col-7 {
      width: 58.33%;
    }

    &.col-8 {
      width: 66.66%;
    }

    &.col-9 {
      width: 75%;
    }

    &.col-10 {
      width: 83.33%;
    }

    &.col-11 {
      width: 91.66%;
    }

    &.col-12 {
      width: 100%;
    }
  }
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.date-picker {
  cursor: pointer;
  font-size: 0.8rem;
  width: 100%;
  border: 1px solid rgba(90, 90, 90, 0.4509803922);
  background: rgba(36, 36, 36, 0.4117647059);
  border-radius: 8px;
  padding: 10px;
  color: #fbfbfb;
}

.MuiLinearProgress-root {
  border-radius: 50px;
}

.tes {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  color: #ececec;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tes input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #c2c6ce;
  border-radius: 50%;
}

.tes:hover input ~ .checkmark {
  background-color: var(--color-sec);
}

.tes input:checked ~ .checkmark {
  background-color: var(--color-sec);
}

.tes .checkmark:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fbfbfb;
}
.Wallet {
  border: none;
  color: #dcdcdc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 0.8rem;
  border-radius: 16px !important;
  gap: 10px;
}
.wallet-button {
  /* position: absolute; */
  right: 0;
  top: -35px;
  display: flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* background: #fbfbfb; */
  /* box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2); */
  /* padding: 20px; */
  /* border-radius: 0 0 16px 16px; */
  // flex-direction: column;
  border-radius: 16px;
  /*
  &__settings {
    cursor: pointer;
    display: flex;
    font-size: 0.8rem;
    color: var(--color-sec);

    .fast {
      font-size: 0.8rem;
      color: #49aec0;
      margin-right: 10px;
    }
  }*/
  border: 1px solid #dcdcdc;
  color: #dcdcdc;
}

.wallet-button-settings {
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  color: var(--color-button);
  bottom: 20px;
  position: fixed;
  left: 20px;
  padding: 10px 20px;
  background: var(--color-sec);
  border-radius: 50px;

  .fast {
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--color-button);
    margin: 0 20px 0 10px;
  }
}

a:link,
a:visited {
  color: (internal value);
  text-decoration: none;
  cursor: auto;
}

a:link:active,
a:visited:active {
  color: (internal value);
}

.wallet-adapter-button {
  border: none;
  color: #dcdcdc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 0.8rem;
  border-radius: 16px !important;
  gap: 10px;
}

.wallet-adapter-button-trigger {
  height: 100%;
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: white;
}

.wallet-adapter-button[disabled] {
  background: #404144;
  color: #999;
  cursor: not-allowed;
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
}

.wallet-adapter-button-end-icon {
  margin-left: 12px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  position: relative;
  display: inline-block;
}

.wallet-adapter-dropdown-list {
  position: absolute;
  z-index: 99;
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 10px;
  padding: 10px;
  top: 100%;
  right: 0;
  margin: 0;
  list-style: none;
  background: #fbfbfb;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  height: 37px;
  color: #3a3a3a;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: var(--color-sec);
  color: #fbfbfb;
}

.wallet-adapter-modal-collapse-button svg {
  align-self: center;
  fill: #999;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active
  svg {
  transform: rotate(180deg);
  transition: transform ease-in 150ms;
}

.wallet-adapter-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity linear 150ms;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  min-width: 20px !important;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: white;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill 200ms ease 0s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #ce3030;
}

.wallet-adapter-modal-overlay {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wallet-adapter-modal-container {
  display: flex;
  margin: 3rem;
  min-height: calc(100vh - 6rem);
  /* 100vh - 2 * margin */
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .wallet-adapter-modal-container {
    margin: 1rem;
    min-height: calc(100vh - 2rem);
    /* 100vh - 2 * margin */
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1050;
  max-width: 400px;
  border-radius: 10px;
  background: #191919;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  flex: 1;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-title {
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 50px;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 374px) {
  .wallet-adapter-modal-title {
    font-size: 18px;
  }
}

.wallet-adapter-modal-list {
  margin: 0 0 12px 0;
  padding: 0;
  width: 100%;
  list-style: none;
  padding: 20px;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  font-weight: 400;
  border-radius: 0;
  font-size: 18px;
  color: #ffffff !important;
  padding: 20px;
  margin: 5px 0;

  &:hover {
    background-color: var(--color-sec);
    color: #fbfbfb !important;

    span {
      color: #fbfbfb;
    }
  }
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon,
.wallet-adapter-modal-list .wallet-adapter-button-end-icon img,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
  width: 20px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
  margin-left: auto;
  font-size: 14px;
  opacity: 0.6;
  color: #d68486;
}

.wallet-adapter-modal-list-more {
  cursor: pointer;
  border: none;
  padding: 12px 24px 24px 12px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #383838;
}

.wallet-adapter-modal-list-more svg {
  transition: all 0.1s ease;
  fill: var(--color-sec);
  margin-left: 0.5rem;
}

.wallet-adapter-modal-list-more-icon-rotate {
  transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px 24px;
  box-sizing: border-box;
}

.wallet-adapter-modal-middle-button {
  display: block;
  cursor: pointer;
  margin-top: 48px;
  width: 100%;
  background-color: #512da8;
  padding: 12px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  color: #fff;
}

.main-item {
  padding: 10px;
  background-color: #fff;
  width: 700px;
}

.background-masker {
  background-color: #fff;
  position: absolute;
}

.btn-divide-left {
  top: 0;
  left: 25%;
  height: 100%;
  width: 5%;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }

  100% {
    background-position: 800px 0;
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 70px;
  position: relative;
}

.static-background {
  background-color: #f6f7f8;
  background-size: 800px 104px;
  height: 70px;
  position: relative;
  margin-bottom: 20px;
}

.shared-dom {
  width: 800px;
  height: 110px;
}

.sub-rect {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  float: left;
  margin: 20px 20px 20px 0;
}

.pure-background {
  background-color: #eee;
}

.css-dom:empty {
  width: 280px;
  height: 220px;
  border-radius: 6px;
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;

  background-image: radial-gradient(circle 16px, lightgray 99%, transparent 0),
    linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray),
    linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray),
    linear-gradient(#fff, #fff);

  background-size: 32px 32px, 200px 32px, 180px 32px, 230px 16px, 100% 40px,
    280px 100%;

  background-position: 24px 30px, 66px 30px, 24px 90px, 24px 142px, 0 180px, 0 0;
}

.adapter-react-button {
  display: inline-flex;
  color: #ffffff;
  cursor: pointer;
  height: 36px;
  //max-width: 150px;
  background-color: #e1d5d500;
  border: none;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  //padding: 0 24px;
  //font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 48px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  white-space: pre;
}

.adapter-react-button:focus {
  outline: none;
}

.adapter-react-button:focus-visible {
  outline: 2px solid white;
}

.adapter-react-button:not([disabled]):hover {
  filter: brightness(0.8);
}

.adapter-react-button[disabled] {
  // background: #404144;
  color: #999;
  cursor: not-allowed;
}

.adapter-react-button .button-icon {
  margin-right: 12px;
}

.adapter-react-button .button-icon,
.adapter-react-button .button-icon img {
  display: block;
  width: 28px;
  height: 28px;
}

/* modal */
.adapter-modal {
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  inset: 0;
  /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  z-index: 999;
  padding: 40px 20px 20px;
  opacity: 0;
  pointer-events: none;
}

.adapter-modal-fade-in {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
}

.adapter-modal-wrapper {
  width: 100%;
  max-width: 400px;
  max-width: 400px;
  background-color: #000000;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  font-size: 2rem;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  font-family: "DM Sans", "Roboto", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.adapter-modal-wrapper .adapter-list {
  padding-bottom: 48px;
}

.adapter-modal-header {
  position: relative;
  padding: 64px 48px 48px;
}

.adapter-modal-header .adapter-modal-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
}

@media (max-width: 374px) {
  .adapter-modal-header .adapter-modal-title {
    font-size: 18px;
  }
}

.adapter-modal-header .close-button {
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  background: #1a1f2e00;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
  top: 18px;
  right: 18px;
  -webkit-tap-highlight-color: transparent;
}

.adapter-modal-header .close-button:focus {
  outline: none;
}

.adapter-modal-header .close-button:focus-visible {
  outline: 2px solid white;
}

.adapter-modal-header .close-button::before,
.adapter-modal-header .close-button::after {
  content: "";
  display: block;
  width: 19px;
  height: 2px;
  background-color: #777;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.adapter-modal-header .close-button::before {
  transform: rotate(45deg);
}

.adapter-modal-header .close-button::after {
  transform: rotate(-45deg);
}

.adapter-modal-header .close-button:hover::before,
.adapter-modal-header .close-button:hover::after {
  background-color: antiquewhite;
}

/** WalletItem */
.adapter-wallet-item .adapter-react-button {
  width: 100%;
  background-color: transparent;
}

.adapter-wallet-item .status-text {
  opacity: 0.6;
  margin-left: auto;
  font-size: 14px;
  font-weight: 400;
}

/** dropdown */
.adapter-dropdown {
  position: relative;
  z-index: 99;
  display: inline-block;
}

.adapter-dropdown-collapse {
  position: absolute;
  margin: auto;
  top: 100%;
  right: 0;
  left: 0;
  display: inline-flex;
}

.adapter-dropdown-list {
  margin: 0 auto;
  width: 150px;
  list-style-type: none;
  border-radius: 10px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  flex-direction: column;
  position: relative;
  top: 5px;
  padding: 5px;
  overflow: hidden;
  height: min-content;
  background: #2c2d30;
}

.adapter-dropdown-list li {
  display: block;
  margin: auto;
  color: white;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  height: 37px;
  border: none;
  border-radius: 6px;
  outline: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.adapter-dropdown-list li:not([disabled]):hover {
  background-color: #373c47;
}
