.dropdown-menu {
  width: 200px;
  position: absolute;
  top: 50px;
  list-style: none;
  text-align: start;
  border-radius: 0 16px 16px 16px;
  padding: 10px;
  background: #fbfbfb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dropdown-menu li {
  cursor: pointer;
}

.dropdown-menu li:hover {
  color: #49aec0;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #606060;
  padding: 5px;

  &:hover {
    color: #49aec0;
    cursor: pointer;
  }
}
.dropdown-link-sub {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #49aec0;
  padding: 5px;
  //background: #49aec0;

  border-radius: 10px;

 /* &:hover {
    color: #ececec;
    cursor: pointer;
  }*/
}
@media screen and (max-width: 960px) {
}
