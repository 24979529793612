.group-cards {
  align-items: flex-start !important;
}

.card {
  //background: rgba(0, 0, 0, 0.28);
  background: none;
  padding: 15px;
  border-radius: 8px;
  position: relative;
  border: 1px solid #414141;
  cursor: pointer;
  transition: all 0.3s ease;
  pointer-events: visibleFill;
  //min-height: 473px;

  &.card-sale {
    border-color: #29b11a;
    border-width: 2px;
  }

  &.card-coming {
    border-color: #f0e477;
  }

  .affiliate {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 16px 16px 0 0;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f0e477;
  }

  .group-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
    flex-direction: column;
    align-items: flex-end;

    &__status {
      font-size: 0.7rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      border-radius: 50px;
      padding: 5px 8px;

      &.salelive {
        background: #55b6554b;
        color: #55b655;
      }

      &.canceled {
        background: #acacac4b;
        color: #acacac;
      }

      &.ended {
        background: #8727274b;
        color: #e77f7f;
      }

      &.finalized {
        background: #ce1c524b;
        color: #ce1c52;
      }

      &.claimAllowed {
        background: #8000804b;
        color: #fbfb;
      }

      &.coming {
        background: #f0e4774b;
        color: #f0e477;
      }
    }
  }

  .group-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    &__logo {
      position: relative;

      img {
        width: 60px;
        height: 60px;
        border-radius: 16px;
      }

      .token-logo {
        position: absolute;
        bottom: 0;
        left: -5px;
        background: #000;
        padding: 10px;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }

    &__status {
      display: flex;
      flex-direction: column;
      width: 100%;

      &--name {
        color: var(--color-sec);
        font-weight: 600;
      }

      &--type {
        color: var(--color-pri);
        font-size: 0.7rem;
      }
    }
  }

  .group-values {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    position: relative;
    justify-content: center;
    align-items: center;

    &__value {
      background: rgba(0, 0, 0, 0.28);
      border-radius: 8px;
      display: flex;
      padding: 9px 13px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 5px;
      font-size: 0.6rem;
      color: var(--color-pri);

      p {
        font-size: 0.6rem;
        color: #989898;
      }
    }

    &__value-flex {
      display: flex;
      width: 100%;
      justify-content: space-between;
      background: rgba(0, 0, 0, 0.28);
      padding: 5px 10px;
      border-radius: 8px;
      gap: 20px;

      .flex-value {
        font-size: 0.6rem;
        display: flex;
        width: 100%;

        justify-content: space-between;
        padding: 0 5px;
        color: var(--color-pri);

        p {
          font-size: 0.6rem;
          color: #989898;
        }
      }
    }
  }

  .group-value-max {
    text-align: center;
    border-radius: 8px;
    width: 100%;
    font-size: 0.8rem;
    display: flex;
    color: #989898;
    padding: 9px 13px;
    justify-content: center;
    align-items: center;
  }

  .group-progress {
    margin: 10px 0;

    &__progress {
      display: flex;
      font-size: 0.7rem;
      justify-content: space-between;

      .label,
      .percentage {
        font-size: 0.7rem;
        color: #8b8b8b;
      }
    }

    &__values {
      display: flex;
      font-size: 0.7rem;
      justify-content: space-between;

      .min,
      .max {
        font-size: 0.7rem;
        color: #8b8b8b;

        p {
          font-size: 0.6rem;
          color: #49aec0;
        }
      }
    }
  }

  .group-time {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
    color: #d38c8d;
    &__label {
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 1rem;
    }

    &__time {
      color: #d38c8d;
      font-size: 1rem;
    }
  }

  .footer-card {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    color: #fbfbfb;

    &__type {
      font-size: 0.8rem;
    }

    &.sale,
    &.coming {
      margin-top: 20px;
    }

    .group-actions {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;
      z-index: 9;

      &__icon {
        cursor: pointer;
        pointer-events: visibleFill;
        z-index: 100;
      }
    }
    .fav {
      color: #d38c8d;
      fill: #d38c8d;
      stroke: #d38c8d;
    }
    .not {
      color: #e2c851;
      fill: #e2c851;
      stroke: #e2c851;
    }
  }
}

.group-tags {
  display: flex;
  justify-content: center;
  gap: 5px;
  height: 30px;

  .tags {
    display: flex;
    height: 28px;
    width: 47px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
    cursor: pointer;
    pointer-events: all;
    color: #fbfbfb;

    &.safu {
      background: linear-gradient(45deg, #865bbe, #48148d);
    }

    &.audit {
      background: linear-gradient(45deg, #9ba5c9, #0e2787);
    }

    &.kyc {
      background: linear-gradient(45deg, #98d7af, #147738);
    }

    &.doxx {
      background: linear-gradient(45deg, #dbb077, #b88038);
    }

    &.alpha {
      background: linear-gradient(45deg, #283043, #2a354f);
    }
  }
}
