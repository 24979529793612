.stake-form {
  background: #f0f0f0;
  padding: 15px 20px;
}

.group-spad {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
  background: rgba(25, 25, 25, 0.219607) !important;

  &__item {
    &--title {
      color: #fbfbfb;
      font-size: 0.8rem;
      font-weight: 100;
    }

    &--value {
      font-size: 1.2rem;
      font-weight: 700;
      margin-top: 5px;
      color: var(--color-sec);
    }
  }

  &__percente {
    position: absolute;
    right: 20px;
    color: #fbfbfb;
    bottom: 10px;
    font-size: 0.8rem;
    font-weight: 500;
  }
}

.group-wallet {
  display: flex;
  gap: 20px;
  position: relative;
  //flex-direction: column;
  //height: 420px;
  justify-content: space-between;

  &__title-card {
    font-size: 1.1rem;
    color: #acacac;
    font-weight: 100;
  }

  &__wallet {
    display: flex;
    gap: 10px;
    align-items: center;

    &--items {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 10px 20px;
      position: relative;

      &.center {
        border-right: 1px solid #a1a1a17c;
      }

      &__title {
        color: #49aec0;
        font-size: 0.8rem;
        font-weight: 300;
      }

      &__value {
        font-size: 1.2rem;
        color: #e9e9e9;
        font-weight: 300;
        margin-top: 10px;
      }

      &--total {
        position: absolute;
        right: 20px;
        bottom: 10px;
        font-size: 0.8rem;
        font-weight: 500;
        color: #fbfbfb;
      }
    }
  }
}

table {
  width: 100%;

  th {
    text-align: left;
    font-size: 0.8rem;
    color: #ebebeb;
  }
}
