.bg-modal {
  height: min-content;
  width: 280px;
  border-radius: 10px;
  background: #191919;
  border: none;
  position: absolute;
  margin: auto;
  padding: 0px 0px 20px 0px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);

  .modal-box {
    padding: 10px 15px;

    .header-modal {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        width: auto !important;
        min-width: auto;
        padding: 0;
        color: var(--color-pri) !important;
      }
    }

    .title-modal {
      margin: 20px 0 10px;
    }
  }
}
