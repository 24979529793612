.dropdown {
  background: #000000;
  border-radius: 8px;
  z-index: 11;
  color: var(--color-sec) !important;
  cursor: pointer !important;

  .dropdown-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  ul {
    li {
      list-style-type: none;

      button {
        color: var(--color-pri) !important;
      }
    }
  }
}
