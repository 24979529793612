.content-values {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: relative;
    z-index: 1;
    width: 300px;
    margin: 30px;
  }

  .group-info-solpad {
    display: flex;
    gap: 10px;
    flex-direction: column;

    &__values {
      display: flex;
      align-items: center;
      padding: 10px;
      gap: 20px;
      /* width: 400px; */
      background: rgb(255 164 164 / 2%);
      border-radius: 16px;
      justify-content: space-between;

      &--title {
        font-size: 0.9rem;
        color: #dcdcdc;
      }

      &--values {
        color: var(--color-sec);
        font-weight: 600;
        font-size: 1.6rem;
      }
    }
  }
}

.btn-group-buy {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  a {
    background: var(--color-sec);
    color: var(--color-button);
    padding: 10px 20px;
    border-radius: 16px;
    cursor: pointer !important;
    transition: all ease 300ms;

    &:hover {
      filter: brightness(0.9);
    }
  }
}
