.trending {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  background: #030303;

  &__container {
    margin: auto;
    width: 100%;
    max-width: 1100px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #fbfbfb;
    gap: 20px;
    justify-content: space-between;
    flex-direction: row-reverse;

    &--title {
      padding-right: 20px;
      font-size: 0.8rem;
      display: contents;
    }

    &--list {
      font-size: 0.8rem;
    }
  }
}

.g-trending {
  display: flex;
  gap: 5px;
  width: 90%;
  align-items: center;
}

.value-sol {
  display: flex;
  align-items: center;
  cursor: pointer;

  .name {
    font-size: 0.7rem;
    color: #fbfbfb;
  }

  .value {
    font-size: 0.7rem;
    color: #fbfbfb;
    margin-left: 5px;
  }
}

.group-itens-trending {
  width: 100%;
  display: flex;
  align-items: center;

  .itens-trending {
    display: flex;
    align-items: center;
    gap: 20px;

    .trending-itens {
      display: flex;
      gap: 10px;
      align-items: center;

      .trending-order {
        font-size: 0.8rem;
        color: var(--color-sec);
      }

      .trending-name {
        font-size: 0.8rem;
      }

      .trending-icon {
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }
    }
  }
}

.marquee {
  width: 93%;
  overflow: hidden;
  white-space: nowrap;
  padding: 2px;
}
.marquee .itens-trending {
  animation: marquee 40s linear infinite;
  display: inline-flex;
  padding-left: 100%;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

@media (max-width: 979px) {
}
