.navbar {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 1rem;
  z-index: 10;
  //background-image: url("../../assets/images/back-top.png");
  //background-repeat: no-repeat;
  width: 100%;
  //height: 20vh;
  background-size: contain;
  position: sticky !important;
  top: 0;
  transition: all ease 400ms;

  &.menuFixo {
    background-color: #111111c2;
  }
}

.group-navbar {
  max-width: 1100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 30px;
  position: relative;
}

.navbar-logo {
  margin-top: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  height: 60px;
  list-style: none;
  text-align: center;
  justify-content: end;
  border-radius: 16px;
  padding: 0 10px;
}

.nav-item {
  display: flex;
  align-items: center;
}

.nav-links {
  color: white;
  font-size: 0.8rem;
  font-weight: 300;
  text-decoration: none;
  padding: 0.5rem 1rem;

  i {
    margin-left: 10px;
    font-size: 0.7rem;
  }
}

.nav-links:hover {
  color: #f8ba0f;
  cursor: pointer;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .btn-group-buy {
    a {
      width: 100%;
      text-align: center;
    }
  }

  .navbar {
    background: #030303;
    height: 60px !important;
    display: flex !important;
    align-items: center !important;
  }

  .group-navbar {
    padding: 0 20px;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 30px;
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 45px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: flex-start;
  }

  .nav-item {
    flex-direction: column;
  }

  .nav-menu.active-nav-menu {
    background: #030303;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow: auto;
  }

  .dropdown-menu {
    width: 100% !important;
    position: initial !important;
    border-radius: 0 !important;
    background: var(--color-sec) !important;
    z-index: 9;

    .dropdown-link {
      color: #fbfbfb !important;
      text-align: center !important;
      margin: 10px 0 !important;
    }
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    color: #fbfbfb;
  }

  .navbar-logo {
    display: none;
  }

  .menu-icon {
    display: block;

    i {
      color: #fff;
      font-size: 2rem;
    }
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }
}
