@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  //font-size: 1rem;
  font-weight: 300;
  font-family: Urbanist, sans-serif;
  //color: #fbfbfb;
}

html,
#root {
  min-height: 100vh;
  color: #fbfbfb;
  background: var(--background);
  line-height: normal;
}

input,
select,
textarea {
  font-size: 0.8rem;
  width: 100%;
  border: 1px solid #5a5a5a73;
  background: #24242469;
  border-radius: 8px;
  padding: 10px;
  color: #fbfbfb;

  option {
    font-size: 0.8rem;
    color: #fbfbfb;
    border-radius: 16px;
  }
}

.title-input {
  font-size: 0.7rem;
  margin-bottom: 5px;
  color: #acacac;
}

.group-safe {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  .title-safe {
    font-size: 0.8rem;
    color: #ececec;
  }

  .value-safe {
    font-size: 0.9rem;
    color: #989898;

    &.expired {
      color: #d84141 !important;
    }
  }
}

.warning-message {
  color: #babd41;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 0.8rem;
  justify-content: flex-end;
}

.secret-key {
  word-wrap: break-word;
  margin: 10px 0;
  font-size: 0.8rem;
}

button {
  min-width: 150px;
  background: none;
  cursor: pointer;
  border: none;
  transition: filter 400ms;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  padding: 9px 19px;
  color: var(--color-button);

  &:hover {
    filter: brightness(0.8);
  }

  &.default {
    background: none;
    border: 1px solid #fbfbfb !important;
    color: #fbfbfb;
  }

  &.next {
    background: var(--color-sec);
    color: var(--color-button);
    border: 1px solid var(--color-sec) !important;
  }

  &.simple-button {
    background: var(--color-sec);
    color: #fbfbfb;
    border: 1px solid var(--color-sec) !important;
    min-width: 100%;
    margin: 10px 0;
  }

  &.simple-button-default {
    background: var(--color-sec);
    color: #fbfbfb;
    border: 1px solid var(--color-sec) !important;
    min-width: 100%;
    margin: 10px 0;
  }

  &.unstake {
    background: #e86d6d;
    border: 1px solid #e86d6d !important;
    color: #fbfbfb;
    width: 100%;
  }
}

.group-buttons {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 20px;
}

hr {
  border: 1px solid;
  border-bottom: none;
  border-color: #57575783;
}

.container-launch {
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;

  .content-launch {
    max-width: 1200px;
    margin: 0 auto;

    .text-title {
      font-size: 1.4rem;
      color: #acacac;
      font-weight: 600;
      margin: 10px 0;
    }

    .text-sub-title {
      font-size: 0.9rem;
      color: #9d9c9c;
    }
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.home {
  position: relative;
  width: 100%;

  .group-logo-home {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    margin-bottom: 30px;
  }
}

.group-left {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.group-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-end;
}

.group-links {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;

  .btn-links {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    color: #dcdcdc;

    .label-button {
      width: max-content;
    }

    &:hover {
      color: var(--color-sec);

      img {
        filter: brightness(0.8);
      }
    }
  }
}

.group-links-footer {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row;
  padding-bottom: 10px;

  .btn-links {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    .label-button {
      width: max-content;
    }

    &:hover {
      color: var(--color-sec);

      img {
        filter: brightness(0.8);
      }
    }
  }
}

.title-section-form {
  text-align: center;
  //color: var(--color-sec);
  font-size: 1.1rem;
  margin-top: 20px;
}

.time-line {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;

  .group-step {
    display: flex;
    gap: 20px;

    .step {
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 15px;
      min-width: 200px;
      height: 70px;
      justify-content: flex-start;
      background: rgba(25, 25, 25, 0.219607);
      border-radius: 16px;

      .step-number {
        color: #a1a1a1;
      }

      .step-title {
        color: #a1a1a1;

        p {
          font-size: 0.7rem;
          color: #a1a1a1;
        }
      }
    }

    .step-actived {
      display: flex;
      gap: 20px;
      align-items: center;
      background: var(--color-sec);
      padding: 15px;
      border-radius: 16px;
      min-width: 200px;
      height: 70px;
      justify-content: flex-start;

      .step-number {
        color: var(--color-button);
      }

      .step-title {
        color: var(--color-button);

        p {
          color: var(--color-button);
          font-size: 0.7rem;
        }
      }
    }
  }
}

.form {
  background: rgb(0 0 0 / 28%);
  padding: 30px;
  margin: 5px 0;
  width: 100%;
  border-radius: 16px;

  &.form-custom {
    padding: 20px 30px 0;
  }
}

.no-data {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.card-contract {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #414141;
  padding: 20px;
  border-radius: 16px;
  cursor: pointer;

  .group-name-contract {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contract-name {
      font-size: 0.8rem;
      color: #fbfbfb;
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  .group-values-locker {
    &__title {
      font-size: 0.7rem;
      color: #ececec;
    }

    &__value {
      font-size: 0.9rem;
      font-weight: 400;
      color: #989898;

      &.expired {
        color: #d32929 !important;
      }
    }
  }
}

.btn-back {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  width: 70px;
  //color: #ff847e;
}

.title-form {
  display: none;
}

.title-step {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-sec);
}

.sub-title-step {
  color: #9d9c9c;
}

.group-filters {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .filter-tab {
    display: flex;
    align-items: flex-end;
    gap: 20px;

    .item-tab {
      cursor: pointer;
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;

      .name-tab {
        font-size: 0.8rem;
        color: #8d8d8d;
      }

      .actived {
        font-size: 0.8rem;
        color: #e2e2e2;
      }
    }
  }
}

.header-tab {
  display: flex;
  justify-content: space-evenly;
  background: none;
  width: 100%;
  margin: 20px 0 10px;

  .header-tab-name {
    cursor: pointer;
    width: 100%;
    color: #fbfbfb;
    text-align: center;
    font-size: 0.8rem;
    padding: 10px;
    //border-bottom: 2px solid #8d8d8d;

    &.actived {
      background: var(--color-sec);
      color: var(--color-button);
      border-radius: 8px;
      //border-color: #ff6e71 !important;
    }
  }
}

.legend {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;

  p {
    font-size: 0.9rem;
    color: #fbfbfb;
  }
}

.group-header-token {
  position: relative;
}

.header-token-banner {
  position: relative;

  .img-banner {
    img {
      height: 315px;
    }
  }

  .header-logo-token {
    position: absolute;
    bottom: -44px;
    left: calc(50% - 50px);
    padding: 10px;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }

  .header-token-logo {
    position: absolute;
    bottom: 0;
    left: -3px;
    background: #000;
    padding: 10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 17px;
      height: 17px;
    }
  }
}

.group-name-launch {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  font-size: 1.2rem;
  //color: var(--color-sec);
  font-weight: 600;
  gap: 10px;

  .edit-icon {
    border: 1px solid;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: var(--color-sec);
      color: #fbfbfb;
    }
  }
}

.group-tag-social {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-status {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  &__status {
    border-radius: 8px;
    margin-top: 5px;
    padding: 5px 20px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.salelive {
      background: linear-gradient(45deg, #8cd88c, #55b655);
      color: #0c091c;
    }

    &.canceled {
      background: linear-gradient(45deg, #e7e7e7, #acacac);
      color: #0c091c;
    }

    &.ended {
      background: linear-gradient(45deg, #cf7474, #872727);
      color: #e7e7e7;
    }

    &.finalized {
      background: linear-gradient(45deg, #fc6492, #da6e8f);
      color: #e7e7e7;
    }

    &.claimAllowed {
      background: linear-gradient(45deg, #800080, rgb(4, 4, 230));
      color: #ffffff;
    }

    &.coming {
      background: linear-gradient(45deg, #f1eaa7, #f0e477);
      color: #0c091c;
    }
  }

  &__affiliate {
    background: linear-gradient(45deg, #ecee92, rgb(230, 207, 4));
    margin-top: 5px;
    padding: 5px 20px;
    border-radius: 8px;
    font-size: 0.8rem;
    color: #0c091c;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.group-invest {
  display: flex;
  justify-content: space-between;

  .invest {
    span {
      color: var(--color-sec);
    }
  }
}

.group-aff {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: #ececec;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #ececec;
  }
}

.create-type-name {
  font-size: 1.2rem;
  //color: var(--color-sec);
  padding-bottom: 10px;
  text-align: center;
}

.group-progress {
  &__progress {
    display: flex;
    font-size: 0.8rem;
    justify-content: space-between;
    margin-bottom: 10px;

    .label,
    .percentage {
      font-size: 0.7rem;
    }
  }

  &__values {
    display: flex;
    font-size: 0.7rem;
    justify-content: space-between;

    .min,
    .max {
      font-size: 0.7rem;
      color: #fbfbfb;

      p {
        font-size: 0.6rem;
        color: #d69799;
      }
    }

    .max {
      text-align: right;
    }
  }
}

.pool-status {
  text-align: center;
  font-size: 0.8rem;
  color: #fbfbfb;
}

p {
  color: #fbfbfb;
}

.pool-group {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  &__item {
    display: flex;
    gap: 5px;
    align-items: center;

    a {
      color: #49aec0;
      cursor: pointer !important;
      text-decoration: none;
      font-size: 0.8rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.amount-max-sol {
  font-size: 0.8rem;
}

.status-time {
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.group-affiliate {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;

  .item-affiliate {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    font-size: 0.7rem;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 5px;
    color: #fbfbfb;

    &:last-child {
      border-bottom: none;
    }

    .amount {
      color: var(--color-sec);
    }
  }
}

.warning {
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--color-sec);
  padding: 15px;
  margin: 10px 0;
  word-break: break-all;

  .text-warning {
    font-size: 0.9rem !important;
  }

  &.yellow {
    border: 1px solid #ff9900;
    background: #fcd9a4c9;
    word-break: initial;
  }
}

.text-whitelist {
  font-size: 0.8rem;
  color: #8d8d8d;
  margin: 20px 0;
}

.item-whitelist {
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 0;
  color: #fbfbfb;

  &:last-child {
    border-bottom: none;
  }
}

.paginator {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  margin-top: 10px;
  align-items: center;
  color: #fbfbfb;

  button {
    width: 20px !important;
    border: 1px solid #fbfbfb;
    padding: 5px 10px;
    min-width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #fbfbfb;
    margin: 0 5px;

    .btn-previous {
      cursor: pointer;
      color: #fbfbfb;
    }
  }
}

.group-contribute {
  margin-top: 30px;

  .input-contribute {
    display: flex;
    align-items: center;

    input {
      width: 100%;
      border-radius: 8px 0 0 8px !important;
    }

    .btn-contribute {
      background: var(--color-sec);
      color: #fbfbfb;
      border-radius: 0 8px 8px 0;
      cursor: pointer;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 400ms;

      &:hover {
        filter: brightness(0.8);
        background: var(--color-sec);
      }
    }
  }
}

.latest-pools-container {
  width: 100%;
}

.content-latest {
  .latest-pools-title {
    display: flex;
    gap: 10px;
    font-weight: 100;
    align-items: center;
    color: var(--color-pri);
    position: relative;

    a {
      color: var(--color-sec) !important;
      font-weight: 600 !important;
      font-size: 1.2rem;
      cursor: pointer;
    }
  }

  .latest-pools-group-items {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #19191938;
    padding: 10px;
    border-radius: 8px;

    .latest-pools-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 20px;
      transition: all 200ms;
      color: #fbfbfb;

      &:hover {
        background: var(--color-sec);
        border-radius: 8px;

        .latest-pools-items-group {
          &__name {
            color: var(--color-pri);

            p {
              color: var(--color-pri);
            }
          }

          &__image {
            img {
              filter: brightness(0.8);
            }
          }
        }
      }

      .latest-pools-group-item {
        &__stake {
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;

          img {
            width: 15px;
            height: 15px;
          }
        }
      }

      .latest-pools-items-group {
        display: flex;
        align-items: center;
        gap: 10px;

        &__image {
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }

        &__name {
          color: #fbfbfb;
          cursor: pointer;
          font-weight: 600;

          p {
            color: #8c8a8a;
            margin-top: -2px;
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

.footer-home {
  width: 100%;
  color: #dcdcdc;
  border-radius: 16px;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  font-size: 0.8rem;
}

.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: 2px !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-x-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.card-hover {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    scale: 1.05;
    transition: all 0.1s ease-in 0s;
  }
}
