.group-tabs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .tabs {
    display: flex;
    gap: 10px;

    .tab {
      width: 100%;
      padding: 5px 0;
      background: none;
      color: var(--color-sec);
      border: 1px solid var(--color-sec);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        filter: brightness(0.9);
      }

      &.active {
        background: var(--color-sec);
        color: var(--color-button);
      }
    }
  }
}

.group-his {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .tabs {
    display: flex;
    gap: 10px;
    border-top: 1px solid #e6e6e6;

    .tab {
      width: 100%;
      padding: 5px 0;
      background: none;

      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }
}

.tab-title {
  color: #347682;
  font-size: 1.1rem;
}

.tab-subtitle {
  color: #d6d6d6;
  font-size: 0.8rem;
}

.tab-content {
  padding: 2px !important;

  &__item {
    display: flex;
    border-radius: 16px;
    width: 100%;
    border: 1px solid #bdbdbd !important;
    padding: 5px 20px !important;
    align-items: center;
    gap: 20px;
    color: #fbfbfb;

    &--chain {
      width: 100%;
      text-align: end;

      &__title {
        color: #49aec0;
        font-size: 0.8rem;
      }

      &__value {
        font-size: 1.2rem;
        color: #fbfbfb;
      }
    }
  }
}

.stake-earn {
  &__group-input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--input {
      width: 100%;
      display: flex;
      align-items: flex-end;

      &--item {
        width: 100%;
        text-align: end;
        margin: 0 10px 0 20px;

        p {
          color: #cecece;
          font-size: 0.8rem;
        }

        .group-input {
          width: 100% !important;
        }
      }
    }
  }

  &__tabs {
    display: flex;
    gap: 10px;
    flex-direction: column;

    .tab {
      width: 100%;
      border: 1px solid var(--color-sec);
      text-align: center;
      padding: 10px 20px;
      border-radius: 8px;
      color: var(--color-sec);
      cursor: pointer;

      &.active {
        background: var(--color-sec);
        color: var(--color-button);
      }
    }
  }

  .form-value-estimated {
    color: #fbfbfb;
  }

  .value-estimated {
    margin-top: 20px;

    &__item {
      display: flex;
      align-items: center;
      color: #fbfbfb;

      p {
        font-size: 0.8rem;
        color: #fbfbfb;
      }

      strong {
        font-size: 1.1rem;
        color: var(--color-sec);
      }
    }
  }
}

.tab-container {
  height: 397px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
