.sol {
  --background: #060606;
  --color-pri: #ececec;
  --color-sec: #0d8154;
  --color-sec-op: #0d815420;
  --color-button: #ececec;
}

.trx {
  --background: #060606;
  --color-pri: #ececec;
  --color-sec: #ca090b;
  --color-sec-op: #ca090b20;
  --color-button: #ececec;
}

.bnb {
  --background: #060606;
  --color-pri: #ececec;
  --color-sec: #ecda37;
  --color-sec-op: #ecda3720;
  --color-button: #1f1f1f;
}
